import React from 'react'
import styled from 'react-emotion'
import { One, Row, RowGroup, Three } from '../../components/Grid'
import SEO from '../../components/SEO'
import { H1, H2, H4 } from '../../components/Style'
import ContactForm from '../../components/ContactForm'
import { FONT_SMALL } from '../../constants'
import Layout from '../../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/courses",
      "name": "Courses"
    }
  },{
    "@type": "ListItem",
    "position": 2,
    "item": {
      "@id": "https://500tech.com/courses/angular",
      "name": "Angular"
    }
  }]
}`

export default () => {
  return (
    <Layout>
      <SEO
        path="/courses/angular"
        title="Angular course by 500Tech"
        description="This course covers all the practical aspects of developing applications with React as a UI layer and Redux as a data management and server communication layers."
        ld={breadcrumbs}
      />

      <Row>
        <H1>Angular Course</H1>

        <Three>
          This course covers all the practical aspects of developing
          applications with Angular. By the end of the course, students will be
          able to join existing Angular projects and start developing features.
        </Three>
      </Row>

      <RowGroup>
        <H2>Syllabus</H2>
        <Row>
          <Three>
            This hands-on training consists of eight modules that cover both
            basic concepts as well as most of the common pain points engineers
            face daily when developing single-page applications with Angular.
          </Three>
        </Row>

        <SmallText>
          <Row>
            <One>
              <H4>I. The Development Environment</H4>
              <p>
                Introduction to the modern development environment and
                technologies for front­end development
              </p>
              <List>
                <ListItem>Overview of the stack - NPM / Webpack</ListItem>
                <ListItem>Working with the Angular CLI </ListItem>
                <ListItem>
                  Understanding the project structure conventions and{' '}
                </ListItem>
                <ListItem>Setting the IDE for Angular development</ListItem>
                <ListItem>Best practices</ListItem>
              </List>
            </One>
            <One>
              <H4>II. Angular architecture and concepts</H4>
              <p>Understanding the core concepts and design behind Angular. </p>
              <List>
                <ListItem>The Renderer</ListItem>
                <ListItem>Change detection system </ListItem>
                <ListItem>Patterns in use</ListItem>
                <ListItem>The core entities of Angular</ListItem>
                <ListItem>The use of javascript decorators</ListItem>
                <ListItem>Best practices and relevant references.</ListItem>
              </List>
            </One>
            <One>
              <H4>III. TypeScript Primer</H4>
              <p>
                Getting familiar with TypeScript, the default language for
                Angular application development
              </p>
              <List>
                <ListItem>Module system </ListItem>
                <ListItem>Classes and Interfaces</ListItem>
                <ListItem>Arrow functions and Template strings</ListItem>
                <ListItem>Working with types</ListItem>
              </List>
            </One>
            <One>
              <H4>IV. Angular Module System</H4>

              <List>
                <ListItem>The need for modules</ListItem>
                <ListItem>The bootstrapping process</ListItem>
                <ListItem>Modules API and usage</ListItem>
                <ListItem>Best practices</ListItem>
              </List>
            </One>
          </Row>

          <Row>
            <One>
              <H4>V. Components Deep Dive</H4>
              <p>
                The component is a core building block of an Angular
                application. In this module, we will deep dive into the
                component metadata API.
              </p>
              <List>
                <ListItem>Building a basic component</ListItem>
                <ListItem>Shadow DOM</ListItem>
                <ListItem>Templates and Styles</ListItem>
                <ListItem>The Component class</ListItem>
                <ListItem>Data bindings and Template syntax</ListItem>
                <ListItem>Angular core directives</ListItem>
                <ListItem>Components types review</ListItem>
                <ListItem>
                  Data projection, building a Wrapper component
                </ListItem>
                <ListItem>Component inputs - Working with properties</ListItem>
                <ListItem>Component outputs - Creating custom events</ListItem>
                <ListItem>
                  Components communication using local variables{' '}
                </ListItem>
                <ListItem>Best practices</ListItem>
              </List>
            </One>
            <One>
              <H4>VI. Dependency injection</H4>
              <p>
                Angular uses dependency injection for instantiating and wiring
                object together.
              </p>
              <List>
                <ListItem>Understanding the role of the Provider</ListItem>
                <ListItem>Creating and using a class provider</ListItem>
                <ListItem>Other types of providers</ListItem>
                <ListItem>
                  Understanding the dependencies injectors hierarchy ­
                </ListItem>
                <ListItem>Common patterns and best practices</ListItem>
                <ListItem>Angular built-in providers</ListItem>
              </List>
            </One>
            <One>
              <H4>VII. Angular Modules</H4>
              <p>
                As a framework, Angular introduces several modules that are
                aimed for common development challenges.
              </p>
              <List>
                <ListItem>
                  Angular HttpClient Module - communication with the server{' '}
                </ListItem>
                <ListItem>
                  Angular Reactive Forms - managing complexed forms{' '}
                </ListItem>
                <ListItem>Angular Router - navigation system</ListItem>
              </List>
            </One>
            <One>
              <H4>State Management (4th day - optional)</H4>
              <p>
                To build a robust architecture for a large Angular app, we use a
                state management solution to orchestrate a unidirectional data
                flow and a single source of truth between components.
              </p>
              <List>
                <ListItem>Managing a Reactive State</ListItem>
                <ListItem>
                  Single Source of Truth and unidirectional data flow
                </ListItem>
                <ListItem>Handling Immutability</ListItem>
                <ListItem>
                  Difference between solutions - NgRx / Redux / MobX
                </ListItem>
                <ListItem>Deep-dive into the solution of choice</ListItem>
              </List>
            </One>
          </Row>
        </SmallText>
      </RowGroup>

      <Row>
        <Three>
          During the course, the students will be practicing taught material on
          their laptops and will be able to ask questions related to their
          current projects.
        </Three>
      </Row>

      <ContactForm />
    </Layout>
  )
}

const SmallText = styled.div`
  font: ${FONT_SMALL};
`

const List = styled.ul``

const ListItem = styled.li`
  padding-left: 20px;
  margin-bottom: 5px;

  &:before {
    content: '✓';
    margin-right: 10px;
    margin-left: -20px;
  }
`
